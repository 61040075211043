import React, { useState, useEffect, useContext } from 'react';
import PassportForm from '../components/Passport/PassportForm';
import PassportList from '../components/Passport/PassportList';
import { Button, Drawer, message, Flex, Space, Grid, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';
import { PlusOutlined } from '@ant-design/icons';
import { set } from 'lodash';


const { useBreakpoint } = Grid;

const PassportPage = ({ rl }) => {
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [passports, setPassports] = useState([]);
    const [periodPassports, setPeriodPassports] = useState([]);
    const [edit, setEdit] = useState(null)
    const [dateFilter, setDateFilter] = useState('')
    const [dataDisplayed, setDataDisplayed] = useState(false)

    const { request, loading } = useHttp();
    const { token, checkRole, userId } = useContext(AuthContext)

    const isMobile = useBreakpoint().xs;

    const fetchPassports = async (limit) => {
        try {
            let url = (checkRole(['user'])) ? `o/api/passport/user/${userId}` : `o/api/passport${limit === 'true' ? '?limit=true' : ''}` //rl === 'user' && 
            const response = await request(url, 'GET', null, {
                Authorization: `Bearer ${token}`
            });
            setPassports(response);
            setPeriodPassports(response)
            setDataDisplayed(true)
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    // Initial Load: Fetch first 50 rows
    useEffect(() => {
        fetchPassports('true')
    }, []);

    // Subsequent Load: Fetch the rest of the data
    useEffect(() => {
        fetchPassports('false')
    }, [dataDisplayed]);

    useEffect(() => {
        handlePeriodFilter(dateFilter)
    }, [passports]);

    // useEffect(() => {
    //     if (!passports || passports.length === 0) { fetchPassports() }
    // }, []);

    const emptyFields = {
        title: '',
        machineModel: '',
        serialNumber: '',
        installationDate: '',
        deliveryDate: '',
        deliveryTask: '',
    }

    const newPassport = () => {
        setEdit(emptyFields);
        showDrawer();
    }

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const onCloseDrawer = () => {
        setIsDrawerVisible(false);
    };

    const handleFormDirection = (values) => {

        if (edit._id) {
            handleFormUpdate(values)
        } else {
            handleFormSubmit(values)
        }
    }

    const handlePeriodFilter = (el) => {

        if (el) {
            setDateFilter(el)
            let period = {
                start: (new Date(el[0] ?? new Date('1970-01-01'))).setHours(0, 0, 0, 0),
                finish: (new Date(el[1])).setHours(23, 59, 59, 999) ?? new Date()
            }
            let passportsWithPeriodFilter = passports?.filter((ps) => (new Date(ps.createdAt) >= period.start
                && new Date(ps.createdAt) <= period.finish))
            setPeriodPassports(passportsWithPeriodFilter)
        } else {
            setPeriodPassports(passports)
        }
    }

    const handleFormUpdate = async (values) => {
        const updatedPassport = {
            deliveryDate: dayjs(values.deliveryDate),
            nextMaintenanceDate: dayjs(values.nextMaintenanceDate),
            warranty: values.warranty,
            comment: values.comment,
            ...values
        }
        const id = values.record._id;
        try {
            const response = await request(`o/api/passport/${id}`, 'PUT', updatedPassport,
                { Authorization: `Bearer ${token}` }
            )
            setPassports(passports.map(u => u._id === id ? response : u))
            setEdit(null);
            onCloseDrawer(); // Close the Drawer after form submission
            message.success(`Паспорт системы для ${values.record.installation.machine.brand} ${values.record.installation.machine.model} ${values.record.installation.machine.serialNumber} успешно обновлен`);
        } catch (error) {
            console.error('Error updating passport:', error);
        }
    }

    const handleFormSubmit = async (values) => {
        // Handle form submission logic (e.g., submit data to backend)
        const newPassport = {
            installation: values.task,
            deliveryDate: () => {
                if (new Date() > dayjs(values.deliveryDate)) {
                    return dayjs(values.deliveryDate)
                } else {
                    return dayjs(new Date())
                }
            },

            equipment: values.record.equipment,
            maintenance: [],
            nextMaintenanceDate: dayjs(values.nextMaintenanceDate) || (new Date(values.deliveryDate).getTime() + 183 * (24 * 60 * 60 * 1000)),
            warranty: values.warranty,
            comment: values.comment,
            contract: values.contract,
            subContract: null,
        }

        try {
            const response = await request('o/api/passport', 'POST', newPassport,
                { 'Authorization': `Bearer ${token}` });
            // console.log(newPassport.installation, values.task, response.installation._id)
            setPassports([...passports, response]);
            // Set passport._id in task record

            // const taskresponse = await request(`o/api/task/${response.installation._id}`, 'PUT',
            //     { passport: response._id },
            //     { 'Authorization': `Bearer ${token}` });
            // console.log('task updated', taskresponse);
            //---------------------------------------------------------------------
            // const formDataToSend = new FormData();
            // Object.entries({ passport: response._id }).forEach(([key, value]) => {
            //     formDataToSend.append(key, value);
            // });
            // const taskResponse = await axios.put(
            //     `/o/api/task/${response.installation._id}`,
            //     formDataToSend,
            //     {
            //         headers: {
            //             'Content-Type': "multipart/form-data",
            //             'Authorization': `Bearer ${token}`
            //         }
            //     },
            // )
            // if (!taskResponse.status == 200) {
            //     throw new Error('Failed to update contract');
            // }
            // // const jsonResponse = await response.json();
            // const jsonResponse = taskResponse.data

            // //---------------------------------------------------------------------

            // console.log('task updated', jsonResponse);
            setEdit(null);
            onCloseDrawer();
            message.success(`Паспорт системы для ${values.record.machine.brand} ${values.record.machine.model} ${values.record.serialNumber} успешно добавлен`);
        } catch (error) {
            console.error(error);
        }
    };

    const handleViewDetails = (passportId) => {
        // Logic to view details of a specific Passport entry
    };

    const handleDelete = async (id) => {
        try {
            await request(`o/api/passport/${id}`, 'DELETE', null, {
                Authorization: `Bearer ${token}`
            });
            setPassports(passports.filter((passport) => passport._id !== id));
            message.success('Паспорт удален');
        } catch (error) {
            console.error('Error deleting passport:', error);
            message.error('Ошибка удаления паспорта');
        }
    }

    const handleEdit = (values) => {
        let transformed = {
            ...values,
            deliveryDate: dayjs(values.deliveryDate),
            installationDate: dayjs(values.installationDate),
            nextMaintenanceDate: dayjs(values.nextMaintenanceDate),
            warrantyFinishDate: dayjs(values.warrantyFinishDate),
        }
        setEdit(transformed)
        // console.log(transformed)
        showDrawer()
    }

    return (
        <div>
            <Flex wrap="wrap" gap="small" align='center' justify='space-between' >
                <Space align='baseline' size={'middle'} >
                    <h2 style={{ marginBottom: 0, marginTop: 0 }}>Паспорт оборудования</h2>
                    {false && <Button type="primary" //checkRole(['admin', 'manager'])
                        onClick={newPassport}
                        shape={isMobile ? 'circle' : ''}
                        icon={<PlusOutlined />}
                        style={{ borderRadius: '20px' }}
                    >{isMobile ? '' : 'ДОБАВИТЬ'}</Button>}
                </Space>
                <Space align='baseline' size={'small'} justify={'end'} >
                    <p>Период: </p>
                    <DatePicker.RangePicker
                        format={'DD.MM.YYYY'}
                        popupStyle={{ width: '290px' }}
                        onChange={handlePeriodFilter}
                        popupClassName='rangeDatePicker'
                    // style={{ marginBottom: '1rem' }}
                    />
                    {/* <Button type="primary"
                        icon={<SearchOutlined />}
                        shape={isMobile ? 'circle' : ''}
                        onClick={() => { }}
                    >{isMobile ? '' : 'Показать'}</Button> */}
                </Space>
            </Flex>
            <Drawer
                title={edit?._id ? "Изменить паспорт" : "Добавить новый паспорт"}
                width={560}
                onClose={onCloseDrawer}
                open={isDrawerVisible}
                destroyOnClose={true}
            >
                <PassportForm editPassport={edit} onSubmit={handleFormDirection} />
            </Drawer>
            <PassportList
                isMobile={isMobile}
                passports={periodPassports} onViewDetails={handleViewDetails} onEdit={handleEdit} onDelete={handleDelete}
                loading={loading}
            />

        </div>
    );
};

export default PassportPage;
